import './index.css';
import gamestackTexturePlaceholder from 'assets/gamestack-login-placeholder.jpg';

import shutthetwentyTexture from 'assets/shutthe20.png';
import tilebloxsTexture from 'assets/tilebloxs.png';
import visionic1Texture from 'assets/visionic1.png';
import visionic2Texture from 'assets/visionic2.png';
import wpbs1Texture from 'assets/wpbs71.png';
import wpbs2Texture from 'assets/wpbs72.png';

import iphone11 from 'assets/iphone-11.glb';
//import macbookPro from 'assets/macbook-pro.glb';
import Footer from 'components/Footer';
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import Intro from 'pages/Home/Intro';
import Profile from 'pages/Home/Profile';
import ProjectSummary from 'pages/Home/ProjectSummary';

import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const disciplines = ['build powerful digital solutions'];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const projectOne = useRef();
  const projectTwo = useRef();
  const projectThree = useRef();
  const details = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [intro, projectOne, projectTwo, projectThree, details];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, projectOne, details];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div className="home">
      <Helmet>
        <title>Meerkat Software</title>
        <meta
          name="description"
          content="Meerkat Software – developer working on web &amp; mobile
          apps with a focus on motion and user experience design."
        />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />
      <ProjectSummary
        id="apps"
        sectionRef={projectOne}
        visible={visibleSections.includes(projectOne.current)}
        index={1}
        title="Vision.iC Mobile"
        description="Project Management Application made during my apprenticeship for Communic Unternehmensberatung GmbH - stay organized and connected between your company members. Bring your team's work together in one shared space and get uninterrupted feedback from your supervisor's."
        buttonText="Communic GmbH"
        buttonLink="https://communic.eu"
		secondButtonText="View in AppStore"
		secondButtonLink="https://apps.apple.com/de/app/vision-ic/id1239415203"
        model={{
          type: 'phone',
          alt: 'Smart Sparrow lesson builder',
          textures: [
            {
              src: visionic2Texture,
              //srcSet: `${gamestackTexture} 254w, ${gamestackTextureLarge} 508w`,
			  srcSet: `${visionic2Texture} 254w`,
              placeholder: gamestackTexturePlaceholder,
            },
            {
              src: visionic1Texture,
              //srcSet: `${gamestackTexture2} 254w, ${gamestackTexture2Large} 508w`,
			  srcSet: `${visionic1Texture} 254w`,
              placeholder: gamestackTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="project-2"
        alternate
        sectionRef={projectTwo}
        visible={visibleSections.includes(projectTwo.current)}
        index={2}
        title="Feinstaubmessung Raspberry Pi"
        description="Augsburg's second place science award winner 2021. Interface for connecting Raspberry Pi's air particles sensor to an iOS enddevice."
        buttonText="Read more"
        buttonLink="https://www.augsburg.de/aktuelles-aus-der-stadt/detail/wissenschaftspreis-augsburger-schulen-2020-21-vergeben"
        secondButtonText="View in AppStore"
		secondButtonLink="https://apps.apple.com/us/app/feinstaubmessung-raspberry-pi/id1558706861"
		model={{
          type: 'phone',
          alt: 'App login screen',
          textures: [
			{
			  src: tilebloxsTexture,
			  srcSet: `${wpbs2Texture} 254w`,
			  placeholder: gamestackTexturePlaceholder,
			},
			{
			  src: shutthetwentyTexture,
			  srcSet: `${wpbs1Texture} 254w`,
			  placeholder: gamestackTexturePlaceholder,
			},
			],
        }}
      />
      <ProjectSummary
        id="project-3"
        sectionRef={projectThree}
        visible={visibleSections.includes(projectThree.current)}
        index={3}
        title="Casual Games"
        description="Simple casual iOS games downloaded and enjoyed by over 2000 users. Give it a try and download for free from AppStore."
        buttonText="Shut The 20!"
        buttonLink="https://apps.apple.com/us/app/shutthe20/id1537296130"
		secondButtonText="Tile bloxs"
		secondButtonLink="https://apps.apple.com/us/app/tile-bloxs/id1520438257"
        model={{
          type: 'phone',
          alt: 'Annotating a biomedical image in the Slice app',
			textures: [
			{
			  src: tilebloxsTexture,
			  srcSet: `${tilebloxsTexture} 254w`,
			  placeholder: gamestackTexturePlaceholder,
			},
			{
			  src: shutthetwentyTexture,
			  srcSet: `${shutthetwentyTexture} 254w`,
			  placeholder: gamestackTexturePlaceholder,
			},
			],
        }}
      />
      <Profile
        sectionRef={details}
        visible={visibleSections.includes(details.current)}
        id="details"
      />
      <Footer />
    </div>
  );
};

export default Home;
